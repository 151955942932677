<template>
    <v-list two-line class="bor py-0 mt-1">
        <v-hover class="h-full" height="100%">
            <v-list-item slot-scope="{ hover }"
                :class="`elevation-${hover ? 1 : 0} ${hover ? 'subsecondary' : 'white'} rounded-lg`">
                <v-list-item-avatar>
                    <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                    <!-- <v-img :src="user.avatar"></v-img> -->
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <router-link :to="{
                            name: 'user.views', params: {
                                f_id: o_md5(user.id), ids:
                                    user.id
                            }
                        }">{{ user.name }}</router-link>
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle>About user</v-list-item-subtitle> -->
                    <!-- <v-list-item-subtitle v-html="user.about"> </v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-icon>
                    <v-btn small :loading="loadingInv" outlined rounded
                        :color="isInvitationSuccess === true ? 'primary' : 'secondary'"
                        @click="sentInvitationRequest()">
                        <v-icon small :color="isInvitationSuccess === true ? 'primary' : 'secondary'">
                            {{ icons.mdiPlus }}
                        </v-icon>
                        {{ isInvitationSuccess === true ? 'Invited' : 'Invite' }}
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-hover>
    </v-list>
</template>
<script>
import { mdiPlus } from '@mdi/js';
import { Drequest } from '@/plugins/Drequest';
var md5 = require("md5")
export default {
    props: {
        user: {
            type: Object,
            default: () => { },
        }
    },
    data: () => ({
        isInvitationSuccess: false,
        loadingInv: false,
        icons: { mdiPlus }
    }),
    methods: {
        o_md5(root_link){
            return md5(root_link)
        },
        sentInvitationRequest() {
            var formColaborator = {
                "owner": this.$store.getters.getUser.id,
                "recipient": this.user.id,
            }
            if (this.isInvitationSuccess === false) {
                this.loadingInv = true
                Drequest.api("colaboration.send")
                    .toFormdata(formColaborator)
                    .post((response) => {
                        this.loadingInv = false
                        if (response.success === true) {
                            this.isInvitationSuccess = response.etablished
                            this.$fire({
                                type: response.etablished === true ? "error" : "success",
                                text: response.detail,
                                timer: 3000
                            })
                        }
                        else {
                            this.$fire({
                                type: "error",
                                text: response.detail,
                                timer: 3000
                            })
                        }
                    })
                    .catch((err) => {
                        this.loadingInv = false;
                    });
            }
        },
    },
    setup(props) {
        return {}
    }
}
</script>
<style lang="scss" scoped>

</style>
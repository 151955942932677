<template>
    <div class="d-flex flex-row align-center headertabs">
        <div class="pa-4 initial align-center py-auto my-auto">
            <v-icon size="1em" color="secondary">{{ header.icon }}</v-icon>
        </div>
        <div class="mx-4 accent--text title my-auto">{{ header.title }}</div>
    </div>
</template>
<script>
export default {
    props: {
        header: {
            type: Object,
            default: () => { }
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/tabs.scss';
</style>
<template>
    <v-card class="mx-0 my-3 fill-height" height="auto" v-if="join !== null" flat>
        <v-list-item three-line>
            <v-list-item-avatar tile size="80">
                <v-img src="@/assets/images/avatars/profil.svg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <router-link :to="{
                        name: 'user.views', params: {
                            f_id: o_md5(join.user.id), ids:
                                join.user.id
                        }
                    }">
                    {{ join.isOwner === true ? join.user.firstname : join.recipient.firstname }}</router-link>
                </v-list-item-title>
                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiWeb }}</v-icon>
                    <span class="my-auto ml-1 mb-1 text-caption">{{ join.isOwner === true ? join.user.email :
                            join.recipient.email
                    }}</span>
                </div>
                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiSortCalendarAscending }}</v-icon>
                    <span class="my-auto ml-1 mb-1 text-caption">{{ join.sended_at }}</span>
                </div>
                <!-- <v-row align="center" class="mx-0">
                    <div class="grey--text ml-4 text-caption">{{join.sended_at}}</div>
                </v-row> -->
            </v-list-item-content>

            <v-list-item-action>
                <div v-if="stab === 2">
                    <v-btn small color="secondary" class="px-4 w-full subsecondary" outlined
                        @click="acceptInvitation(join)" :loading="onConf === true ? true : false">
                        Accept
                    </v-btn>
                    <v-btn small color="error" class="px-4 w-full lightError mt-3" outlined
                        @click="deleteInvitation(join)" :loading="onDel === true ? true : false">
                        Reject
                    </v-btn>
                </div>
                <div v-if="stab === 1">
                    <div class="mb-4">
                        <span class="text-md pa-0" v-if="this.join.status === '2'">
                            <v-icon size="13" class="primary--text mr-1">{{ icons.mdiCheckboxMarkedCircle }}
                            </v-icon><span class="primary--text text-caption">Confirmed</span>
                        </span>
                        <span class="text-md pa-0" v-else-if="this.join.status === '0'">
                            <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon>
                            <span class="error--text text-caption">Rejected</span>
                        </span>

                        <span class="text-md pa-0" v-else>
                            <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon><span
                                class="secondary--text text-caption">awaiting</span>
                        </span>
                    </div>
                    <v-btn x-small v-if="this.join.status === '1'" color="error" width="auto"
                        class="px-4 w-full lightError mt-3 ml-auto" outlined @click="cancelInvitation(join)"
                        :loading="onCancel === true ? true : false">
                        Cancel
                    </v-btn>
                </div>

            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>


<script>
// eslint-disable-next-line object-curly-newline
import { mdiMapMarkerOutline, mdiWeb, mdiCheckboxMarkedCircle, mdiAlertCircle, mdiCloseCircle, mdiSortCalendarAscending } from '@mdi/js'
import { ref } from '@vue/composition-api'
var md5 = require("md5")

export default {
    props: {
        join: {
            type: Object,
            default: {}
        },
        stab: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            onGoingOperation: false,
            onConf: false,
            onDel: false,
            onCancel: false,
        }
    },
    methods: {
        acceptInvitation(item) {
            this.onConf = true
            this.$emit('accept', item)
        },
        deleteInvitation(item) {
            this.onDel = true
            this.$emit('delete', item)
        },
        cancelInvitation(item) {
            this.onCancel = true
            this.$emit('cancel', item)
        },
        viewUserInfos() {
            this.$router.push({ name: "user.views", params: { f_id: md5(this.join.user.id), ids: this.join.user.id } })
        },
        o_md5(root_link) {
            return md5(root_link)
        }
    },
    setup(props) {
        const icons = { mdiMapMarkerOutline, mdiWeb, mdiCheckboxMarkedCircle, mdiAlertCircle, mdiCloseCircle, mdiSortCalendarAscending }

        return {
            icons,
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/css/style.scss';
</style>

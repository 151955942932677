var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"bor py-0 mt-1",attrs:{"two-line":""}},[_c('v-hover',{staticClass:"h-full",attrs:{"height":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-list-item',{class:("elevation-" + (hover ? 1 : 0) + " " + (hover ? 'subsecondary' : 'white') + " rounded-lg")},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require("@/assets/images/avatars/profil.svg")}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{
                        name: 'user.views', params: {
                            f_id: _vm.o_md5(_vm.user.id), ids:
                                _vm.user.id
                        }
                    }}},[_vm._v(_vm._s(_vm.user.name))])],1)],1),_c('v-list-item-icon',[_c('v-btn',{attrs:{"small":"","loading":_vm.loadingInv,"outlined":"","rounded":"","color":_vm.isInvitationSuccess === true ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.sentInvitationRequest()}}},[_c('v-icon',{attrs:{"small":"","color":_vm.isInvitationSuccess === true ? 'primary' : 'secondary'}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.isInvitationSuccess === true ? 'Invited' : 'Invite')+" ")],1)],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
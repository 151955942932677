<template>
    <div>
        <v-card id="account-setting-card" tile flat>
            <v-data-table v-if="stab === 0" :headers="headers" :items="joinedData" sort-by="user"
                :loading="this.isDataLoading" loading-text="Loading... Please wait" class="elevation-1 row-pointer"
                style="cursor: pointer" @click:row="(item) => viewInvitation(item)">
                <template v-slot:item.content="{ item }">
                    <networking-card v-if="stab === 0" :join="item"></networking-card>
                    <!-- <section class="hover-item-user flex flex-col justify-center bg-gray-50 text-gray-600">
                        <div class="shadow-lg rounded-lg">
                            <div class="flex items-start">
                                <v-avatar size="60">
                                    <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                                </v-avatar>
                                <div class="flex-grow truncate">
                                    <div class="w-full sm:flex justify-between items-center mb-3">
                                        <h2
                                            class="text-2xl leading-snug font-extrabold text-gray-50 truncate mb-1 sm:mb-0">
                                            Simple Design Tips</h2>
                                    </div>
                                    <div class="flex items-end justify-between whitespace-normal">
                                        <div class="max-w-md text-indigo-100">
                                            <p class="mb-2">Lorem ipsum dolor sit amet, consecte adipiscing elit
                                                sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                        </div>
                                        <a class="d-flex text-indigo-600 rounded-full px-4 py-2 bg-gradient-to-b from-indigo-50 to-indigo-100 hover:from-white hover:to-indigo-50 focus:outline-none focus-visible:from-white focus-visible:to-white transition duration-150 ml-2"
                                            href="#0">
                                            <span class="block mr-4">view profile</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> -->
                </template>
            </v-data-table>
            <v-data-table v-else :headers="headers" :items="joinedData" sort-by="user" :loading="this.isDataLoading"
                loading-text="Loading... Please wait" class="elevation-1 row-pointer" style="cursor: pointer"
                @click:row="(item) => viewInvitation(item)">
                <template v-slot:item.content="{ item }">
                    <invitation-card :stab="stab" @delete="deleteInvitation($event, item)"
                        @cancel="cancelInvitation($event, item)" @accept="acceptInvitation($event, item)" :join="item">
                    </invitation-card>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import InvitationCard from './InvitationCard.vue'
import NetworkingCard from './NetworkingCard.vue'

var md5 = require("md5")
export default {
    props: {
        stab: {
            type: Number,
            default: 0
        }
    },
    components: {
        InvitationCard,
        NetworkingCard
    },
    data() {
        return {
            isDataLoading: true,
            joinedData: [],
            editedItem: {},
            editedIndex: -1,
        };
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        async viewInvitation(item) {
            // this.$router.push({ 
            //     name: "user.views", params: { f_id: md5(item.id), ids: item.id } 
            // })
        },
        initialize() {
            // created list
        },
        async acceptInvitation(item) {
            this.editedIndex = this.joinedData.indexOf(item)
            Drequest.api(`colaboration.accept?id=${item.id}`)
                .get((response) => {
                    if (response.success == true) {
                        this.joinedData.splice(this.editedIndex, 1)
                        this.$fire({
                            type: "success",
                            text: "Invitation accepted!",
                            timer: 3000
                        })
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.isDataLoading = false
                })
        },
        async cancelInvitation(item) {
            this.editedIndex = this.joinedData.indexOf(item)
            Drequest.api(`colaboration.reject?id=${item.id}`)
                .get((response) => {
                    if (response.success == true) {
                        this.joinedData.splice(this.editedIndex, 1)
                        this.$fire({
                            type: "success",
                            text: "Invitation accepted!",
                            timer: 3000
                        })
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.isDataLoading = false
                })
        },
        async deleteInvitation(item) {
            this.editedIndex = this.joinedData.indexOf(item)
            Drequest.api(`colaboration.reject?id=${item.id}`)
                .get((response) => {
                    if (response.success == true) {
                        this.joinedData.splice(this.editedIndex, 1)
                        this.$fire({
                            type: "success",
                            text: "Invitation rejected!",
                            timer: 3000
                        })
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.isDataLoading = false
                })
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.joinedData[this.editedIndex], this.editedItem)
            } else {
                this.joinedData.push(this.editedItem)
            }
            this.close()
        },
    },

    beforeMount() {
        this.isDataLoading = true
        var request = `lazyloading.colaborator?dfilters=on&user_id=${this.$store.getters.getUser.id}`
        var isOwner = true

        if (this.stab === 1) {
            request = `lazyloading.colaborator?dfilters=on&status:eq=2&recipientuser=${this.$store.getters.getUser.id}`
            isOwner = false
        }
        if (this.stab === 2) {
            request = `lazyloading.colaborator?dfilters=on&status:eq=1&recipientuser=${this.$store.getters.getUser.id}`
            isOwner = false
        }
        Drequest.api(request)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.joinedData.push({
                            id: value.id,
                            user: value.user,
                            status: value.status,
                            recipient: value.recipientuser,
                            state: value.state,
                            sended_at: value.created_at,
                            action_at: value.updated_at,
                            isOwner: isOwner
                        })
                    })
                    this.isDataLoading = false
                }
                else {
                    this.isDataLoading = false
                    this.$fire({
                        title: "Error occured!",
                        type: "error",
                        text: "Veuillez recharger la page..!",
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                console.log("err")
                this.isDataLoading = false
            })
    },
    setup() {
        const icons = { mdiMagnify, mdiEye, mdiShareAll }
        const headers = [
            { text: '', value: 'content', align: 'start' }
        ]
        const tab = ref('')
        return {
            tab, icons, headers
        }
    },
}
</script>

<style lang="scss" scoped>
.row-pointer>>>tbody tr :hover {
    // cursor: pointer;
}

.hover-item-user {
    cursor: pointer;
}
</style>